import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/conference/expert-pods"

    return (
        <Layout pageTitle="Expert Pods - Templates">
            <Row>
                <Col>
                    <Link to="/conference/expert-pods/expert-pods-index">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                <h1 className="h1">
                Exhibiting Done Differently                    
                 </h1>
                    
        
    
                </Col>
            </Row>
            <ImageDownload
                root={root}
                name="Expert Pod Promo"
                filename="expert-pod-promo-2"
                text="Use this template to anounce your Expert Pod at the Women's Business Conference You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAE1JvIglys/1hXmjL5ai92qnfpemcTBgA/view?utm_content=DAE1JvIglys&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFnMqX-G3I/937hRr2lS9UNrRAR6skZLg/view?utm_content=DAFnMqX-G3I&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/1H8r8KhTSL-vtNaY0j8ka4W88880IcQh7Bhd9rfFH9Kc/edit?usp=sharing"
            />


            

        </Layout>
    )
}

export default IndexPage
